<template>
  <div class="footer-container">
    <div class="row">
      <div class="col-lg-3 col-12 footer-cols">
        <img
          class="footer-logo"
          :src="require('@/assets/images/logoColoredWhite.png')"
          alt="footer logo image"
        />
        <div class="footer-col-1-text">CONNECT</div>
        <div class="footer-col-1-text">LEARN</div>
        <div class="footer-col-1-text">GROW</div>
        <div class="footer-col-1-button">
          <md-filled-tonal-button class="record-list-read-more-button">
            Get Started
            <i class="mdi mdi-arrow-right"></i>
          </md-filled-tonal-button>
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols hidden-footer-cols">
        <div class="footer-cols-title">Courses</div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-courses-overview')"
        >
          For Adults
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-courses-overview')"
        >
          For Teenagers
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-courses-overview')"
        >
          For Kids
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols hidden-footer-cols">
        <div class="footer-cols-title">Solutions</div>
        <div class="footer-cols-links" @click="$router.push('/k-12-schools')">
          For K-12 education
        </div>
        <div class="footer-cols-links" @click="$router.push('/ib-schools')">
          For IB schools
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/higher-education')"
        >
          For higher education
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/private-sector-ngos')"
        >
          For private sector
        </div>
        <div class="footer-cols-links" @click="$router.push('/public-sector')">
          For public sector and non-profits
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols hidden-footer-cols">
        <div class="footer-cols-title" @click="$router.push('/about-comligo')">
          About us
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/about-comligo/academic-team')"
        >
          Our Academic team
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/about-comligo/teachers')"
        >
          Our teachers
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols hidden-footer-cols">
        <div class="footer-cols-title">Resources</div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-learning-articles')"
        >
          Articles
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-learning-resources')"
        >
          Guides
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-learning-ebooks')"
        >
          Ebooks
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/comligo-spanish-events')"
        >
          Events
        </div>
      </div>
      <div class="col-lg-1 col-4 footer-cols hidden-footer-cols">
        <div class="footer-cols-title">Contact us</div>
        <div
          class="footer-cols-links"
          @click="$router.push('/contact-comligo')"
        >
          Contact
        </div>
        <div class="footer-cols-links" @click="$router.push('/faqs')">FAQs</div>
      </div>
    </div>
    <div class="row footer-bottom-links hidden-footer-cols">
      <div
        class="footer-cols-links col-lg-3 col-4"
        @click="$router.push('/terms-and-conditions')"
      >
        Terms and Conditions
      </div>
      <div
        class="footer-cols-links col-lg-3 col-4"
        @click="$router.push('/privacy-policy')"
      >
        Privacy Policy
      </div>
      <div
        class="footer-cols-links col-lg-3 col-4"
        @click="$router.push('/cancellation-policy')"
      >
        Cancellation Policy
      </div>
      <div
        class="footer-cols-links col-lg-3 col-4"
        @click="$router.push('/accessibility-policy')"
      >
        Accessibility Policy
      </div>
      <div
        class="footer-cols-links col-lg-3 col-4"
        @click="$router.push('/non-discrimination-policy')"
      >
        Non-discrimination Policy
      </div>
      <div
        class="footer-cols-links col-lg-3 col-4"
        @click="$router.push('/cookies-policy')"
      >
        Cookies Policy
      </div>
      <div class="footer-cols-links col-lg-3 col-4" @click="setDialog(true)">
        Cookies Consent Manager
      </div>
    </div>
    <div class="mobile-footer-cols">
      <v-list class="mobile-footer-list-item mobile-footer-items-wrapper">
        <div
          class="mobile-footer-list-items"
          :style="
            item.border
              ? { borderTop: '1px solid #ffffff2e', marginTop: '10vw' }
              : ''
          "
          v-for="item in footer"
          :key="item.title"
        >
          <v-list-group v-model="item.active" no-action>
            <template v-slot:activator="{ props }">
              <v-list-item
                :append-icon="item.active ? 'mdi-menu-up' : 'mdi-menu-down'"
              >
                <v-list-item-title
                  v-bind="props"
                  class="mobile-footer-list-item-title"
                  @click="item.active = !item.active"
                  pre
                >
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item
              class="mobile-footer-items"
              v-for="(subItem, index) in item.items"
              :key="index"
            >
              <v-list-item-title @click="$router.push(subItem.link)">
                {{ subItem.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </div>
    <div class="footer-copyright">
      <div class="footer-cols-links mr-auto">
        © 2024 Comligo. All rights reserved.
      </div>
      <div class="footer-icons-group">
        <div class="footer-icons-button">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Facebook1.png?alt=media&token=6fd2577b-ea81-4609-a01f-5c2859cec84f"
            alt="facebook-logo"
            class="footer-icons-img"
            @click="openLink('https://www.facebook.com/ComligoSpanish')"
          />
        </div>
        <div class="footer-icons-button">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Linkedin1.png?alt=media&token=d76a56c3-a4b6-48ed-84e6-cc26a5b69aab"
            alt="linkedIn-logo"
            class="footer-icons-img"
            @click="
              openLink(
                'https://www.linkedin.com/company/learnspanishonlinecomligo'
              )
            "
          />
        </div>
      </div>
      <div class="footer-copyright-mobile">
        © 2024 Comligo. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "FooterComponent",
  methods: {
    ...mapMutations(["setConsent", "setDialog"]),
    openLink(link) {
      window.open(`${link}`, "_blank").focus();
    },
  },
  data() {
    return {
      footer: [
        {
          title: "Courses",
          items: [
            {
              title: "For Adults",
              link: "/spanish-courses-overview",
            },
            {
              title: "For Teenagers",
              link: "/spanish-courses-overview",
            },
            {
              title: "For Kids",
              link: "/spanish-courses-overview",
            },
          ],
          link: "",
          icon: "mdi mdi-chevron-left",
          active: false,
        },
        {
          title: "Solutions",
          items: [
            {
              title: "For K-12 education",
              link: "/k-12-schools",
            },
            {
              title: "For IB schools",
              link: "/ib-schools",
            },
            {
              title: "For higher education",
              link: "/higher-education",
            },
            {
              title: "For private sector",
              link: "/private-sector-ngos",
            },
            {
              title: "For public sector and non-profits",
              link: "/private-sector-ngos",
            },
          ],
          link: "",
          icon: "mdi mdi-chevron-left",
          active: false,
        },
        {
          title: "About us",
          items: [
            {
              title: "Our Academic team",
              link: "/about-comligo/academic-team",
            },
            {
              title: "Our teachers",
              link: "/about-comligo/teachers",
            },
          ],
          link: "",
          icon: "mdi mdi-chevron-left",
          active: false,
        },
        {
          title: "Resources",
          items: [
            {
              title: "Articles",
              link: "/spanish-learning-articles",
            },
            {
              title: "Guides",
              link: "/spanish-learning-resources",
            },
            {
              title: "Ebooks",
              link: "/spanish-learning-ebooks",
            },
            {
              title: "Events",
              link: "/comligo-spanish-events",
            },
          ],
          link: "",
          icon: "mdi mdi-chevron-left",
          active: false,
        },
        {
          title: "Contact us",
          items: [
            {
              title: "Contact",
              link: "/contact-comligo",
            },
            {
              title: "FAQs",
              link: "/faqs",
            },
          ],
          link: "",
          icon: "mdi mdi-chevron-left",
          active: false,
        },
        {
          title: "Policies",
          items: [
            {
              title: "Terms and Conditions",
              link: "/terms-and-conditions",
            },
            {
              title: "Privacy Policy",
              link: "/privacy-policy",
            },
            {
              title: "Cancellation Policy",
              link: "/cancellation-policy",
            },
            {
              title: "Accessibility Policy",
              link: "/accessibility-policy",
            },
            {
              title: "Non-discrimination Policy",
              link: "/non-discrimination-policy",
            },
            {
              title: "Cookies Policy",
              link: "/cookies-policy",
            },
          ],
          link: "",
          icon: "mdi mdi-chevron-left",
          active: false,
          border: true,
        },
      ],
    };
  },
};
</script>

<style></style>
