<template>
  <div>
    <div v-for="(consentManager, x) in consentList" :key="x">
      <div
        v-if="consentManager.nodeType === 'heading-3'"
        class="cookie-content-title"
      >
        {{ consentManager?.content[0]?.value }}
      </div>
      <div
        v-if="consentManager.nodeType === 'paragraph'"
        class="cookie-expansion-paragraph cookie-paragraph-alt"
      >
        {{ consentManager?.content[0]?.value }}
      </div>
      <div v-if="consentManager.nodeType === 'unordered-list'">
        <v-expansion-panels
          v-model="panel"
          multiple
          class="cookie-expansion-wrapper"
        >
          <v-expansion-panel
            class=""
            v-for="(listItem, i) in consentManager?.content"
            :key="i"
          >
            <v-expansion-panel-title class="cookies-expansion-title-icon">
              <template v-slot:actions>
                <div class="cookies-expansion-title-wrapper">
                  <i class="mdi mdi-menu-down cookie-expansion-icon"></i>
                  {{ listItem?.content[0]?.content[0]?.value }}
                </div>
                <div class="cookie-expansion-toogle">
                  <v-switch
                    v-model="cookies[listItem?.content[0]?.content[0]?.value]"
                    color="orange-darken-4"
                    hide-details
                    v-if="
                      listItem?.content[0]?.content[0]?.value !== 'Necessary'
                    "
                    @click.stop
                  ></v-switch>
                  <div v-else>Always active</div>
                </div>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="course-body-expansion-body">
              <div class="">
                <div v-if="listItem?.content[1]?.nodeType === 'unordered-list'">
                  <div
                    v-for="(listItem2, x) in listItem?.content[1]?.content"
                    :key="x"
                  >
                    <div v-for="(listItem3, w) in listItem2?.content" :key="w">
                      <div
                        v-if="listItem3?.content[0]?.nodeType === 'text'"
                        class="cookie-expansion-paragraph"
                      >
                        {{ listItem3?.content[0]?.value }}
                      </div>
                      <div
                        v-if="listItem3?.content?.length > 1"
                        class="cookie-expansion-url"
                        @click="openLink(listItem3?.content[1])"
                      >
                        {{ listItem3?.content[1]?.content[0]?.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <div :class="overlay ? 'consent-buttons-wrapper' : ''">
      <div class="cookie-expansion-buttons">
        <md-filled-tonal-button @click="acceptAll()">
          Accept all
        </md-filled-tonal-button>
        <md-outlined-button
          class="cookie-expansion-decline-button discover-more-button"
          @click="declineAll()"
        >
          Decline all
        </md-outlined-button>
        <md-filled-tonal-button
          class="cookie-expansion-save-settings"
          @click="saveSettings()"
        >
          Save settings
        </md-filled-tonal-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "consentManager",
  props: ["consentList", "overlay"],
  data() {
    return {
      panel: [0, 1, 2, 3],
      cookies: {
        Necessary: true,
      },
    };
  },
  mounted() {
    const consents = this.getCookie("comligoCookie")
      ? JSON.parse(this.getCookie("comligoCookie"))
      : "";
    this.cookies = consents ? consents : { Necessary: true };
  },
  methods: {
    ...mapMutations(["setNotification", "setDialog"]),
    openLink(link) {
      window
        .open(
          `${
            link?.data?.uri.includes("mail")
              ? ""
              : link?.data?.uri.includes("https")
              ? ""
              : "https://"
          }${link?.data?.uri}`,
          "_blank"
        )
        .focus();
    },
    acceptAll() {
      this.consentList.forEach((header) => {
        if (header.nodeType === "unordered-list") {
          header.content.forEach((list) => {
            if (list.nodeType === "list-item") {
              this.cookies[list?.content[0]?.content[0]?.value] = true;
            }
          });
        }
      });
    },
    declineAll() {
      this.consentList.forEach((header) => {
        if (header.nodeType === "unordered-list") {
          header.content.forEach((list) => {
            if (list.nodeType === "list-item") {
              const name = list?.content[0]?.content[0]?.value;
              if (name !== "Necessary") {
                this.cookies[name] = false;
              }
            }
          });
        }
      });
    },
    saveSettings() {
      this.setCookie("comligoCookie", JSON.stringify(this.cookies), 365);
      this.setNotification({
        show: true,
        title: "Cookie consent settings saved",
        message: "Your settings for cookie consent have been updated",
        type: "success",
      });
      this.setDialog(false);
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie =
        cname +
        "=" +
        cvalue +
        ";" +
        expires +
        ";path=/;Domain=comligo.com;SameSite=None;Secure";
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
};
</script>

<style></style>
