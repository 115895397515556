<template>
  <div class="partner-statements-container">
    <div class="partner-statements-badge">From our courses</div>
    <div class="partner-statements-title">Check what they’re saying</div>
    <div class="swiper-navigation">
      <div class="partner-statements-navigation-buttons swiper-button-prev">
        <i class="mdi mdi-arrow-left"></i>
      </div>
      <div class="partner-statements-navigation-buttons swiper-button-next">
        <i class="mdi mdi-arrow-right"></i>
      </div>
    </div>
    <div>
      <swiper
        ref="swiperRef"
        :slides-per-view="3"
        :space-between="80"
        :loop="true"
        :pagination="{ clickable: true }"
        :speed="500"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
      >
        <swiper-slide
          class="partner-statements-wrapper"
          v-for="(slide, i) in getTestimonials"
          :key="i"
        >
          <div>
            <img
              :src="slide.image"
              class="partner-statements-logos"
              alt="partner thumbnail"
            />
            <div class="partner-statements-text">
              {{ slide.text }}
            </div>
            <div class="partner-statements-name">
              {{ slide.name }}
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import { mapGetters } from "vuex";

SwiperCore.use([Autoplay, Pagination, Navigation]);

export default {
  name: "PartnersStatements",
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapGetters(["getTestimonials"]),
  },
};
</script>

<style></style>
