import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "@material/web/button/filled-button.js";
import "@material/web/button/filled-tonal-button.js";
import "@material/web/button/outlined-button.js";
import "@material/web/checkbox/checkbox.js";
import { PerfectScrollbarPlugin } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/style.css";
import { createHead } from "@unhead/vue";

import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
const head = createHead();

loadFonts();

createApp(App)
  .use(head)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(PerfectScrollbarPlugin)
  .mount("#app");
