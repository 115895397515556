<template>
  <div class="news-letter-container">
    <div class="news-letter-title">Subscribe to our newsletter</div>
    <div class="news-letter-description">
      Unlock language learning tips and updates
    </div>
    <div class="row news-letter-inputs">
      <div class="col-lg-2"></div>
      <div class="col-lg-6 col-12">
        <v-form class="contact-form-inputs" ref="form" @submit.prevent>
          <v-text-field
            label="Email"
            v-model="email"
            :rules="emailRules"
            variant="outlined"
          ></v-text-field>
        </v-form>
      </div>
      <div class="col-lg-2 col-12">
        <md-filled-tonal-button class="get-started-button" @click="sendInfo()">
          Subscribe now
          <i class="mdi mdi-arrow-right"></i>
        </md-filled-tonal-button>
      </div>
      <div class="col-lg-2"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  name: "NewsLetter",
  data() {
    return {
      email: "",
      emailRules: [
        (value) => {
          if (this.validateEmail(value)) return true;

          return "Enter a valid email";
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["setBreadcrumbs", "setNotification"]),
    sendInfo() {
      this.$refs.form.validate();
      if (this.validateEmail(this.email)) {
        axios
          .post(
            "https://restserver-323001.uw.r.appspot.com/api/webpage/contact",
            {
              Email: this.email,
              Interest: "Subscribe to newsletter",
              Message: "I would like to subscribe to the newsletter",
            }
          )
          .then(() => {
            this.setNotification({
              show: true,
              title: "Details sent",
              message:
                "Thank you for providing your information. We will reach out soon.",
              type: "success",
            });
          });
      } else {
        this.setNotification({
          show: true,
          title: "Missing fields",
          message: "Please fill in the email",
          type: "error",
        });
      }
    },
    validateEmail(field) {
      return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(field);
    },
  },
};
</script>

<style></style>
