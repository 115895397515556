<template>
  <div class="our-courses-container">
    <div class="our-courses-badge">From our courses</div>
    <div class="our-courses-title">Overview of our language courses</div>
    <recordListComponent
      class="our-courses-record-list"
      :records="getCourseList"
    />
    <md-filled-tonal-button
      class="get-started-button"
      @click="$router.push('/spanish-courses-overview')"
    >
      View our courses
      <i class="mdi mdi-arrow-right"></i>
    </md-filled-tonal-button>
  </div>
</template>

<script>
import recordListComponent from "./recordListComponentCourses.vue";
import { mapGetters } from "vuex";

export default {
  name: "OurCoursesComponent",
  components: {
    recordListComponent,
  },
  computed: {
    ...mapGetters(["getCourseList"]),
  },
};
</script>

<style></style>
