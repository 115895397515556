<template>
  <div>
    <div class="cookie-dialogue" v-if="show">
      <div>
        <div>
          We use cookies (and other similar techonologies) to improve your
          experience on our site. By using this site you agree to our
          <span
            class="cookie-dialogue-cookie-button"
            @click="$router.push('/policies/6qStBzceL8Hosfwa1DCT7V')"
          >
            Cookie Policy.
          </span>
        </div>
        <div class="cookie-dialogue-consent-button" @click="setDialog(true)">
          You can change your preference at any time.
        </div>
      </div>
      <i
        class="mdi mdi-close cookie-consent-close"
        @click="setDialog(true)"
      ></i>
    </div>
    <div>
      <v-dialog v-model="$store.state.dialog" width="50vw" height="auto">
        <consentManager
          class="consent-manager-wrapper"
          :consentList="getConsent?.fields?.consentManager?.content"
          :overlay="true"
          :class="!$store.state.dialog ? checkStatus() : ''"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import consentManager from "./consentManager.vue";

export default {
  name: "cookieDialogue",
  computed: {
    ...mapGetters(["getConsent", "getDialog"]),
  },
  components: {
    consentManager,
  },
  mounted() {
    this.checkStatus();
  },
  data() {
    return {
      dialog: false,
      show: false,
    };
  },
  methods: {
    ...mapMutations(["setConsent", "setDialog"]),
    getConsentManagerData() {
      axios
        .get(
          `https://cdn.contentful.com/spaces/8nqnktj51aoq/environments/master/entries/bFqmZASFZChyUXX1aDE2m?access_token=njkIU16uuU7MPa5hOvzUO4deV6DFYht3xdwj6KUge-k`
        )
        .then((response) => {
          this.setConsent(response.data);
        })
        .catch(() => {});
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    checkStatus() {
      const consents = this.getCookie("comligoCookie")
        ? JSON.parse(this.getCookie("comligoCookie"))
        : "";
      if (consents) {
        this.show = false;
      } else {
        this.show = true;
      }
      this.getConsentManagerData();
    },
  },
};
</script>

<style></style>
