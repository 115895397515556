export default {
  setServices(state, val) {
    state.services = val;
  },
  setProducts(state, val) {
    state.products = val;
  },
  setCourses(state, val) {
    state.courses = val;
  },
  setCourse(state, val) {
    state.course = val;
  },
  setBreadcrumbs(state, val) {
    state.breadcrumbs = val;
  },
  setArticles(state, val) {
    state.articles = val;
  },
  setArticle(state, val) {
    state.article = val;
  },
  setK12Header(state, val) {
    state.K12Header = val;
  },
  setOurSolutionsHome(state, val) {
    state.ourSolutionsHome = val;
  },
  setOurSolutionsK12(state, val) {
    state.ourSolutionsK12 = val;
  },
  setYourChallenges(state, val) {
    state.yourChallenges = val;
  },
  setCollaborationsTimeline(state, val) {
    state.collaborationsTimeline = val;
  },
  seteBooks(state, val) {
    state.eBooks = val;
  },
  seteBook(state, val) {
    state.eBook = val;
  },
  setCaseStudies(state, val) {
    state.caseStudies = val;
  },
  setCaseStudy(state, val) {
    state.caseStudy = val;
  },
  setK12Providers(state, val) {
    state.k12Providers = val;
  },
  setK12Provider(state, val) {
    state.k12Provider = val;
  },
  setK12ProviderHeader(state, val) {
    state.K12ProviderHeader = val;
  },
  setCookiePolicy(state, val) {
    state.cookiePolicy = val;
  },
  setPricingPlans(state, val) {
    state.pricingPlans = val;
  },
  setFrequentlyAskedQuestions(state, val) {
    state.frequentlyAskedQuestions = val;
  },
  setAboutUsHeader(state, val) {
    state.AboutUsHeader = val;
  },
  setMeetOurTeam(state, val) {
    state.meetOurTeam = val;
  },
  setResourcesHeader(state, val) {
    state.resourcesHeader = val;
  },
  setGuides(state, val) {
    state.guides = val;
  },
  setGuide(state, val) {
    state.guide = val;
  },
  setEvents(state, val) {
    state.events = val;
  },
  setEvent(state, val) {
    state.event = val;
  },
  setConsent(state, val) {
    state.consent = val;
  },
  setNotification(state, val) {
    state.notification = val;
  },
  setDialog(state, val) {
    state.dialog = val;
  },
  setProgress(state, val) {
    state.progress = val;
  },
};
