<template>
  <div class="record-list-container">
    <div class="row justify-content-center">
      <div
        class="col-lg-4 col-12 record-list-item"
        v-for="(courses, i) in records"
        :key="i"
      >
        <div class="record-list-wrapper">
          <div
            v-for="(courseItems, x) in courses.fields.listItem.content"
            :key="x"
          >
            <div v-if="courseItems?.nodeType === 'embedded-asset-block'">
              <img
                class="record-list-image"
                :src="images[i]"
                alt="template card thumbnail"
              />
            </div>
            <div
              v-if="courseItems?.nodeType === 'heading-6'"
              class="record-list-subtext"
            >
              {{ courseItems.content[0].value }}
            </div>
            <div
              v-if="
                courseItems?.nodeType === 'paragraph' &&
                courseItems?.content[0]?.marks[0]?.type === 'bold' &&
                x < 3
              "
              class="record-list-title"
            >
              {{ courseItems.content[0].value }}
            </div>
            <div
              v-if="
                courseItems?.nodeType === 'paragraph' &&
                courseItems?.content[0]?.marks[0]?.type !== 'bold'
              "
              class="record-list-paragraph"
            >
              {{ courseItems.content[0].value }}
            </div>
            <div
              class="record-list-points-group"
              v-if="courseItems?.nodeType === 'unordered-list'"
            >
              <ul
                class="record-list-points"
                v-for="(list, x) in courseItems.content"
                :key="x"
              >
                <li class="record-list-paragraph">
                  <b></b>
                  {{ list?.content ? list?.content[0]?.content[0]?.value : "" }}
                </li>
              </ul>
            </div>
            <div
              v-if="
                courseItems?.nodeType === 'paragraph' &&
                courseItems?.content[0]?.marks[0]?.type === 'bold' &&
                x > 3
              "
              class="record-list-left-wrapper"
            >
              <div class="record-list-tag">
                {{ courseItems.content[0].value }}
              </div>
            </div>
            <div
              class="record-list-right"
              v-if="courseItems?.nodeType === 'heading-3'"
            >
              <md-filled-tonal-button
                class="record-list-read-more-button"
                @click="
                  $router.push(
                    `/course/${
                      courses?.fields?.slug
                        ? courses?.fields?.slug
                        : courses?.sys?.id
                    }`
                  )
                "
              >
                {{ courseItems.content[0].value }}
                <i class="mdi mdi-arrow-right"></i>
              </md-filled-tonal-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["records"],
  name: "recordList",
  data() {
    return {
      images: [],
    };
  },
  watch: {
    records() {
      this.records.forEach((courses, i) => {
        this.getImageURL(
          courses.fields.listItem.content[0].data.target.sys.id,
          i
        );
      });
    },
  },
  methods: {
    getImageURL(assetId, x) {
      if (assetId) {
        axios
          .get(
            `https://cdn.contentful.com/spaces/8nqnktj51aoq/assets/${assetId}?access_token=njkIU16uuU7MPa5hOvzUO4deV6DFYht3xdwj6KUge-k`
          )
          .then((response) => {
            this.images[x] = `https:${response.data.fields.file.url}`;
          });
      }
    },
  },
};
</script>

<style></style>
