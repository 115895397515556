<template>
  <div>
    <div class="transform-language-inner-container">
      <div class="transform-language-title">
        Transform language learning at your institution
      </div>
      <div class="transform-language-description">
        Discover how Comligo can enhance your language programs.
      </div>
      <div class="transform-language-description">Schedule a demo now!</div>
      <div>
        <md-filled-tonal-button
          class="get-started-button"
          @click="$router.push('/book-demo')"
        >
          Get started
          <i class="mdi mdi-arrow-right"></i>
        </md-filled-tonal-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransformLanguage",
};
</script>

<style></style>
