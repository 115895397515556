import { createStore } from "vuex";
import getters from "./getters";
import mutations from "./mutations";

export default createStore({
  namespaced: true,
  state() {
    return {
      homePageHeader: {
        title1: "Learn Spanish.",
        title2: "Online.",
        desc1:
          "Our online classes with native, qualified teachers are dynamic, interactive,",
        desc2:
          "and practical. Join our group lessons today to enhance your Spanish skills.",
        desc3: "lessons.",
        button1: "Get started",
        button2: "Discover more",
        scrollElement: ".our-courses-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/homeHeader1.png?alt=media&token=0b14d873-50df-4509-b9c5-0e20d59df9ed",
      },
      coursesHeader: {
        title1: "Courses",
        desc1: "Join us in mastering Spanish! Whether for business or",
        desc2: "casual conversations, you'll start speaking confidently",
        desc3: "from day one.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-courses-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBGInner.png?alt=media&token=3fd68412-9754-4ec9-a2a8-15063c9f1ec3",
        frame1:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Frame51351.png?alt=media&token=92c1bb9b-3321-4b12-b328-4ed2cb49a795",
        frame2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Frame51352.png?alt=media&token=2ad59530-a764-4e0e-9af3-67342f471b60",
        frame3:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Frame51353.png?alt=media&token=8a749251-6f53-48d7-8d8b-48b261baae78",
      },
      K12Header: {
        title1: "K-12 schools",
        desc1: "Empowering Student Success",
        desc4:
          "Comligo drives student success with cutting-edge educational tools that boost learning outcomes and support academic excellence from Elementary to High School students.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/k12.png?alt=media&token=0b0c43ab-7e23-4d16-85f6-1ad20425240d",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/k12BG.png?alt=media&token=e63f61d4-f62f-42d0-99ad-779ff39afc77",
      },
      AboutUsHeader: {
        title1: "About Us",
        desc1:
          "Join us on a journey to promote Spanish language and culture across the globe.",
        button1: "Get started",
        button2: "Learn more",
        bgInner3:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/plant.png?alt=media&token=bfb654ef-5d3d-41e9-a20c-d7010f03c194",
        scrollElement: ".our-story-title",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/aboutus.png?alt=media&token=2a40a1b2-52d3-46ad-acf6-5238730a6db9",
      },
      IBHeader: {
        title1: "IB Schools",
        desc1: "Empowering Global Learners",
        desc4:
          "Comligo elevates the IB (International Baccalaureate) experience by providing tailored language programs that promote linguistic diversity and academic excellence. Our solutions are designed to help IB students thrive in a globalized world, ensuring they excel in their international education.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/IBschools.png?alt=media&token=c2b383f5-a501-48bb-a958-0665fe8cbc4b",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/IBschoolsBG.png?alt=media&token=e7aeb340-b309-4580-8751-f3bd613d6766",
      },
      higherEducationHeader: {
        title1: "Higher education",
        desc1: "Empowering Academic Excellence",
        desc4:
          "Comligo enhances the higher education experience by delivering specialized language programs that support academic success and global competence. Our tailored solutions are designed to meet the diverse needs of students and faculty, preparing them for a thriving career in a globalized world.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/higherEducation.png?alt=media&token=f7c43227-3201-47f0-b0b3-429c49445a11",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/higherEducationBG.png?alt=media&token=1899f41f-8a64-4e78-a545-e28acc232fdc",
      },
      privateSectorHeader: {
        title1: "Private sector",
        desc1: "Strengthening Communities Through Language",
        desc4:
          "Comligo partners with private sector organizations and NGOs to deliver tailored language programs that enhance community engagement, promote cultural awareness, and improve access to essential services and resources. Our offerings help businesses and NGOs foster a more inclusive environment and address the diverse needs of the communities they serve.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/privateSector.png?alt=media&token=b2c1d092-2506-4a6d-bddd-f49bd003d336",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/provateSectorBG.png?alt=media&token=07d1496d-235a-462b-80ec-796fe398c088",
      },
      corporateSectorHeader: {
        title1: "Corporate Organizations",
        desc1: "Empowering Workforce Excellence",
        desc4:
          "Comligo enhances professional growth by offering Spanish language programs tailored to meet the diverse needs of corporate organizations. These programs equip staff with essential language skills, promoting effective communication and cultural awareness within the workplace.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/privateSector.png?alt=media&token=b2c1d092-2506-4a6d-bddd-f49bd003d336",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/provateSectorBG.png?alt=media&token=07d1496d-235a-462b-80ec-796fe398c088",
      },
      nonProfitsHeader: {
        title1: "Public and non-profits",
        desc1: "Empowering Communities Through Language",
        desc4:
          "Comligo collaborates with public sector organizations to deliver tailored language programs that enhance community engagement, promote cultural awareness, and improve access to essential services and resources. Our offerings help public agencies foster a more inclusive environment and address the diverse needs of the communities they serve.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/nonProfits.png?alt=media&token=7455cda9-4b56-4237-ae11-4ce8c844473c",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/nonProfitsBG.png?alt=media&token=d2639db9-b62d-4a56-a95f-0eee7b94d90f",
      },
      K12ProviderHeader: {
        title1: "DOE-Approved K-12 Programs",
        desc1:
          "Empower your students' education with flexible accredited courses",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/k12providersmap.png?alt=media&token=2261a9c0-3468-4d63-801e-9e87494d10b3",
      },
      resourcesHeader: {
        title1: "Resources",
        desc1:
          "Join us on a journey to promote Spanish language and culture across the globe.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/resourcesImg.png?alt=media&token=a240b149-e0c1-4d24-b0cd-59ae168395bf",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/resourcesBG.png?alt=media&token=c2dd6edd-1d11-418b-a7e0-00c2e18ca402",
      },
      missionsAndVisions: [
        {
          icon: "mdi mdi-rocket-launch",
          title: "Mission",
          description:
            "Our mission is to promote Spanish study and teaching worldwide, fostering a global community of learners and enthusiasts, making high-quality education accessible to everyone.",
        },
        {
          icon: "mdi mdi-eye-outline",
          title: "Vision",
          description:
            "Our vision is to create a cohesive world through Spanish and respectful coexistence with other cultures. We strive to lead in innovative language education, bridging cultural gaps and fostering understanding.",
        },
        {
          icon: "mdi mdi-hand-heart-outline",
          title: "Values",
          description:
            "We value inclusivity by making education accessible globally. We promote intercultural awareness, prioritize personalized support, and advocate for diversity in all our educational initiatives.",
        },
      ],
      meetOurTeam: {
        academicTeam: {
          description:
            "Our academic team is composed of dedicated educational experts committed to providing high-quality Spanish language content and personalized support to students.",
          description1:
            "We have developed a framework to ensure academic quality. An experienced team provides guidance, quality assurance, and processes that ensure the effective implementation of the framework's elements.",
          list: [
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teamthumb6.png?alt=media&token=b48bb3f5-3b36-4c80-989a-f5ba62e3277f",
              title: "JAIRO PÉREZ",
              description:
                "My name is Jairo and I am Mexican. Currently, I live in Montevideo, Uruguay and I work at Comligo Spanish as academic coordinator. I have worked as a teacher in the areas of Human and Artistic Sciences as well as Spanish as a second language. I am a graduate of the National Autonomous University of Mexico.  I am currently finishing a master's at the Universidad de la República in Uruguay. I enjoy reading, art, travel, getting to know different cultures, visiting museums, enjoying nature, and swimming.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Diego%20G.jpeg?alt=media&token=3dc3d293-c0b3-40d7-b46e-8f7b7dadf7f6",
              title: "DIEGO PEREIRA",
              description:
                "Hello, My name is Diego Guilherme Pereira, I was born in the capital of São Paulo, but as a child I moved to the countryside, to the city where I still live today called Pindamonhangaba. Currently I have been working at Comligo for more than 2 years, I have also worked in the administrative sector at the city hall of my city. I graduated from the Business and Innovation Management course at FATEC - Faculdade de Tecnologia do Estado de São Paulo. I have some published articles, such as a scientific initiation work on the feasibility of an App for autonomous services. I am an extremely quiet person, who likes to spend time at home. I like soccer very much, and watching my team's games. I also like to exercise, I like to do weights at the gym.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Julia_Bracelis.png?alt=media&token=0ec70781-cbc7-402c-9e9a-c622139a3639",
              title: "JULIA BRACELIS",
              description:
                "Hello! My name is Julia. I am your Spanish teacher! I'm from Argentina and I've been teaching Spanish to adults, children and young people for 14 years, first in Brazil where I lived for many years and then in Argentina where I started with online classes. I also want to tell you that I am a librarian, I work in a library of the National University of Cuyo, in the city where I currently live: Mendoza. I love reading, literature and the Spanish language. I have two children, a girl and a boy. They are very good students and also love books since they are very young. My greatest satisfaction is to see my students progress in the language. Seeing them overcome difficulties and incorporate the language makes me feel very happy with my profession. I hope that this path we are taking together will be full of moments of joy and satisfaction.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Milagros_De%20la%20Horra.jpg?alt=media&token=0a7afa55-310d-4a07-9771-6f13ed7910ec",
              title: "MILAGROS DE LA HORRA",
              description:
                "Hello! My name is Milagros De La Horra and I am from Rosario, Argentina. I’ve always had passion for teaching and for more than two years I have been doing what I love the most, teaching Spanish to students from all the places and of all ages. During my professional career, I have been a teacher of Spanish as a foreign language, I also have been and editor and proofreader for a local publishing house and I have been leading teenagers to develop their creative writing and find their unique voices. Currently, I work as a Spanish teacher online and for immigrants from my city. I love introducing people to a different language and culture and motivate them to learn through games, videos and literature. In my free time, I like to read, watch series and movies and write poetry. I enjoy assisting to local poetry readings and, when I have the chance, organizing cultural events. I am very happy to meet you and to accompany you in your learning of Spanish.",
              readMore: false,
            },
          ],
        },
        teachers: {
          title: "Comligo Spanish Teachers",
          description:
            "Our teachers are highly qualified and passionate educators who will be by your side to help you succeed in your Spanish goals.",
          title1: "Our Teaching Standards",
          standards: [
            "Teacher selection follows a careful recruitment process to ensure teachers meet our teaching standards",
            "All our teaching materials share an evidence-based pedagogical design that revolves around three core principles",
            "Live classes with native qualified teachers",
            "Teaching materials",
            "Audiovisual materials such as videos and podcast",
            "Text such as blogs and articles",
            "Progress reports",
            "Personalized feedback",
            "And much more",
          ],
          list: [
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherlogo.png?alt=media&token=fd6f06c4-1176-453c-9084-d6a23fa6316a",
              title: "ANGELLIC ROMERO",
              description:
                "My name is Stephanie Angellic Mexico. I am passionate about education and I have the privilege of being a virtual teacher since 2018 and a language teacher since 2016. My academic journey began at the Universidad Autónoma del Estado de México, where I obtained a bachelor's degree. Then, I decided to continue my studies and graduated with a Master's Degree in Education with a focus on Educational Innovation and Technology from the Universidad del Valle de México. Currently, I have a PhD in Human Development from IEXPRO University.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Andrea_Cubillos.jpg?alt=media&token=49bb8d0a-52dd-4ac9-8b85-1c828d89f724",
              title: "ANDREA CUBILLOS",
              description:
                "Hello! My name is Andrea Martínez Cubillos and I am from Bogotá, Colombia. For 22 years I have had the joy of doing what I love the most, teaching Spanish to students of all ages. During my professional career, I have been a teacher of Spanish and literature as a native language and Spanish as a foreign language. Currently, I work as a high school teacher in a high school in Medellin, Colombia. I love reading books with my students and designing projects that motivate my students to learn from the world of literature. In my free time, I like to read and paint. I am good at baking and event decoration; activities that I do when I have free time or when my friends and family require it. I am very happy to meet you and to accompany you in your learning of Spanish.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherThumb1.png?alt=media&token=94a46593-311c-4d16-bf07-91d13bed2a7e",
              title: "GABRIELA NESTARES",
              description:
                "My name is Gabriela. Nice to meet you! I have been teaching language to children and teenagers for 20 years and five years ago I specialized in teaching Spanish to foreign students of different ages and levels. I am also a Sociocultural Anthropologist and I love to know different cultures. I want to tell you that I live in Rosario, Argentina, the city of Leo Messi, a famous soccer player. My city has a beautiful river, the Paraná, with islands and beaches. In summer I love to cross the river by kayak and drink mate on the islands. Do you know what mate is? It's a typical drink in my country, I'll tell you more in the classes. I also like to ride my bike and do yoga. I love music, I am learning how to play the violin. Traveling and getting to know other cultures is one of my favorite activities, see you!",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherThumb2.png?alt=media&token=3929f406-6f38-4c81-88e6-60f296fc83c2",
              title: "GABRIELA SERRANO",
              description:
                "I am Gabriela Guanajuato. I am 37 years old. My teaching experience began in 2007 doing my College internship in daycare centers. After finishing my Bachelor's Degree in Education, I worked as a businesswoman for 6 years as director and owner of a daycare center. After concluding that period I continued working as a teacher of English at various preschool, elementary,middle and high school levels, working in private schools and giving private lessons. I continued in a military high school teaching English and philosophy.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherThumb3.png?alt=media&token=583bd252-536e-40b5-b101-d25690beaf06",
              title: "GADI REYNA MIRANDA",
              description:
                "My name is Gadi, I'm originally from Xochimilco in Mexico City and I live in Coyoacan. Both places are very touristic in my country, that's one of the reasons why I enjoy teaching culture. Also, I come from a family of teachers. My maternal grandparents were among the first teachers in their communities in the 50's. My family history has inspired me as a Mexican woman to dedicate myself to the noble teaching activity since I love teaching people from all over the world including my country, and seeing their faces of satisfaction when they learn in an easy and fun way. During the last 15 years, I have been a language teacher for children, teenagers, and adults, working for different cultural and educational institutions. I also studied graphic communication design, as I love art and playing with images to create knowledge. My hobbies are traveling, eating, dancing, listening to music of different genres, and making friends around the world. It will be a pleasure to teach you Spanish and enjoy learning with me about my rich Hispanic culture.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherlogo.png?alt=media&token=fd6f06c4-1176-453c-9084-d6a23fa6316a",
              title: "INGRID OSPINA",
              description:
                "Hi, I'm Ingrid Ospina from Bogotá, Colombia. I started working as a Spanish teacher for foreigners four years ago. Before that I worked as a workshop leader in NGOs and with children teaching sports and artistic disciplines. I love teaching my mother tongue and being able to share my culture and that of other Spanish speaking countries where I have had the pleasure to be like Chile, Peru, Guatemala, Mexico and Spain. I studied industrial design and then visual arts. I am currently finishing my master's degree in visual poetics in Campinas, Brazil.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Jorge_Navarro.png?alt=media&token=6cb130b1-e1ff-4c1c-a600-02c5a15aefa6",
              title: "JORGE NAVARRO",
              description:
                "My name is Jorge. Nice to meet you! For the last ten years, I have been a Spanish teacher for children of different ages, teenagers, and adults. I am also a translator of Portuguese to Spanish. I love both of my occupations. Also, I would like to tell you I was born in the Colombian Caribbean, specifically in the historic city of Cartagena, which is a Tourist and Cultural site. My city has many beautiful beaches, beautiful natural places, and historical places to visit. It is a hot city, but there is usually a cool breeze. I'll tell you more during class. I love to read, walk or run along the beaches, dance, and listen to Latin music and music from other cultures and languages. Not only that, but I love cooking, and going out with my friends. I like to watch movies when it rains. I am happy when everything is organized and there is good communication. I am happy to meet you and to start a wonderful journey through Spanish and Hispanic culture. Let's have fun!",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Karla_Sanchez%20.jpg?alt=media&token=848f8b3f-2498-4f3a-8626-3d8b7fe84d01",
              title: "KARLA SANCHEZ",
              description:
                "My name is Karla and I will be your Spanish teacher! which excites me a lot and that's why I would like to tell you a little bit about myself. I'm from Mexico, yes, the country of piñatas, mariachi and Frida Kahlo. I am 43 years old, I love to dance, sing, read, and meet new people from all cultures. I love to travel and every time I have the opportunity I do it! I have been teaching Spanish for more than 20 years to people of all ages, children, teenagers, adults and you know what? I LOVE! I am happy to meet you and to be able to help you learn Spanish, you will see how much fun it will be!",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Milena_Martinez.jpg?alt=media&token=bd4ad8d2-2761-41fb-8f56-0a8acfe1b1bb",
              title: "MILENA MARTINEZ",
              description:
                "Colombia stands out in the world for being a great producer of coffee, having a wonderful cuisine, beautiful tourist sites, and one of the regions with the richest fauna and flora of South America, and of course, friendly, hardworking, and kind people. My city, Medellin, known as the capital of the mountains, stands out for its flowers, its festivals, its landscapes, its people, its history, its characters, among others. From this city are the artists Karol G, J Balvin, and Maluma among others... You may know some of them for their excellent artistic career. I can tell you that I like music, trees, traveling, seeing the shape of the clouds, the sea, and observing coffee landscapes. I am also a reader, but above all a book lover. I love books as a vehicle to carry eternal stories and to know the infinite worlds I cannot inhabit. I look forward to seeing you in my classes. I hope to continue learning about the world from the hands of my best teachers: my students. A colorful hug.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Sofia_Ghilioni.jpeg?alt=media&token=c791a031-e9fa-44c6-8626-53034f7abb52",
              title: "SOFIA GHILIONI",
              description:
                "My name is Sofía Ghilioni. I am from Rosario, Argentina. I am a Spanish teacher who enjoys teaching and learning with my students. Likewise, I have been teaching children for many years, and I love it a lot! I love animals. I have a cat named Almudena and a dog named Beth. Luckily, they get along well and play with each other, and it is very nice to see them together. I love national music, international rock, listening to reggaeton, playing the piano, and watching movies of any genre. Furthermore, I also love soccer and travel. I love to know and experience other cultures. And above all, I love the food of each country. I hope we have a great time together. So, I am looking forward to meeting you! Thanks for reading me,",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherThumb4.png?alt=media&token=12d0a715-3522-4708-b671-ccfdab8b45bb",
              title: "VIVIAN SALAMANCA",
              description:
                "My name is Viviana Salamanca. I am from Colombia. I am in Bogota, the capital city. I like Colombia very much because of its people, Colombians like to be friendly, share a lot with family, and enjoy the beautiful places that this country has. My mother tongue is Spanish, but I like English very much. I live with my husband, my daughter, and my cat! I really like online teaching because I meet people from many places and I also learn about their cultures. I know we are going to have a great time and it will be a great adventure.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Julia_Issac.jpg?alt=media&token=0bdb9219-8f87-49bc-8a48-2e7bb83b6d14",
              title: "JULIA ISSACS",
              description:
                "My name is Julia Isaac and I am from Esperanza, province of Santa Fe, Argentina. I am an Instructor of English as a Second Language and of Spanish as a Second Language, in addition to being an English>Spanish Translator with +15 years of professional experience. I spent the last +9 years in the US, working as a Translator, Project Manager and as a Professor of Spanish and Translation. I also speak Portuguese and Italian, and I am currently taking salsa, bachata, and acting classes. I love being active and doing things outdoors whenever the weather is nice. Education and foreign languages are my true passion, and this job enables me to combine both. I am really excited to meet you all!!!",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Betty_Ruiz.jpg?alt=media&token=a27329ac-78ee-4034-a33e-57afc024df20",
              title: "BETTIANA RUIZ",
              description:
                "My name is Bettiana Ruíz. I am from Montevideo, Uruguay. I am a Spanish teacher who enjoys teaching and learning with my students. Likewise, I have been teaching children and adults for many years, and I love it a lot! I love listening to music, watching movies, and reading. I like animals a lot. I have two dogs and a cat. Luckily, they get along well and play with each other, and it is very nice to see them together. Furthermore, I also love long walks at the beach and traveling. I really like to know and experience other cultures. And above all, I like meeting new people from different parts of the world. I hope we have a great time together. So, I am looking forward to meeting you!",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Paloma_Morales.jpg?alt=media&token=de392b9c-cadf-4e27-a858-3713d0161142",
              title: "PALOMA MORALES",
              description:
                "I am from Toluca, Mexico State, I am 39 years old. My teaching experience began in 2008 and I have had the opportunity to work with students of different levels. I have collaborated with public and private institutions and online teaching has allowed me to gain experience in teaching Spanish as a foreign language with students of different nationalities. What I like the most is the cultural richness that can be obtained through learning this wonderful language! I combine teaching practice with administrative activities that have allowed me to manage study trips to Ireland and Canada. I have also collaborated on projects related to teaching Spanish with colleagues from the United States, Brazil, Colombia, Spain and Mexico. I love travelling and hiking, reading and listening to music. I practice mindfulness and I and really enjoy spending time with my family and friends. I'm constantly learning new things.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Screenshot%202024-10-21%20at%2011.48.35%E2%80%AFAM.png?alt=media&token=fae01556-099a-4830-8551-a3655fc33031",
              title: "TAMIA ALEMAN",
              description:
                "My name is Tamia Pacari Aleman Torres and I am from Bogotá, Colombia. I have had the joy and satisfaction to be an English teacher for over three years to teenagers and adults with A1 and A2 levels. I have also had the opportunity to tutor my little cousin in her Spanish lessons in New York City. Talking a little bit about my professional background, I have been a bilingual instructor in a foundation associated with SENA, which is a two-year career organization that gives teenagers an opportunity to start their professional lives. In the present time, I teach personalized English and Spanish classes to adults who want to become bilingual improving their skills in a foreign language. In my free time, I like to read, exercise and spend time with my family. I am a very familiar person and that helps me to become a better human being, enjoying the beautiful things god has given to me. I am very happy to join this amazing team and I hope to contribute positively to society by teaching Spanish to teenagers, letting them know that education has the power to change the world we live in.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Victoria_Arroyo.jpg?alt=media&token=f750225c-d895-45e3-92df-213e0dc8251c",
              title: "VICTORIA ARROYO",
              description:
                "My name is Victoria Arroyo. I am from Cordoba, Argentina. I am a Spanish teacher who enjoys teaching and learning with my students. I have been teaching children and adults for many years, and I love it! I love animals. I have a dog named Cata, she’s 5 years old. We enjoy going for long walks and playing with her toys. I love watching movies and listening to music. I also enjoy reading novels. In addition, I love swimming and travel. I love meeting new people and experiencing other cultures. I hope we have a great time together. So, I am looking forward to meeting you!",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Tamara_Martinez.jpg?alt=media&token=c10139c9-9a69-4fd8-9d6b-9d980d12238c",
              title: "TAMARA MARTINEZ",
              description:
                "My name is Tamara Martínez Kiss and I live in Montevideo, Uruguay. For the past 5 years I have been dedicated to teaching English as a foreign language. At the same time I am pursuing a degree in teaching Language and Literature in Spanish. I enjoy teaching students of all ages, but especially at high school or “liceo” level, as we call it in my country. I love interacting with teenagers. Currently, I work as a high school teacher in a high school in Montevideo, of which I have been a part since its foundation. My pedagogical approach focuses mainly on neurodidactics meaning understanding and taking into account brain processes to achieve the best learning outcomes. In my free time, I like to read and knit. I learned to crochet at the age of 7 with my grandmother, and it is an activity I still enjoy to this day. I am very happy to meet you and to accompany you in your learning of Spanish.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Karina_Hernandez.JPG?alt=media&token=419991c9-0074-462b-b59a-932a27669529",
              title: "KARINA HERNANDEZ",
              description:
                "My name is Karina and I am from Mexico. I live in Mexico City. I am a teacher of English and Spanish for foreigners. I work in a junior high school as an English teacher, but I’ve been a teacher in different schools and different levels. I have more than 15 years of teaching experience. I am a graduate in English teaching at the Universidad Nacional Autónoma de México (UNAM). In this same university, I did two diploma courses: Applied linguistics and Spanish teaching for foreigners. I also studied a Use of the ICT for teaching master´s degree at the Rioja University, Spain. I love reading, meditating and listening to rock music.",
              readMore: false,
            },
            {
              image: "",
              title: "Become the Next",
              description:
                "Passionate about teaching Spanish? We're looking for dedicated educators to join our team at Comligo. Click the button below and help us inspire learners worldwide!",
              button: "Send CV",
            },
          ],
        },
      },
      understandingCourses: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseThumb1.png?alt=media&token=e3ea491b-fe4f-460b-b671-a212d10edc23",
          title: "Spanish for K-12 Students",
          description:
            "The variety of Spanish courses for K-12 students will allow the student to learn the language while also gaining an understanding of Hispanic culture and traditions.",
          listTitle: "Skills Acquired:",
          list: [
            "Enhanced communication skills in Spanish",
            "Greater hispanic cultural awareness and understanding",
            "Improved reading and comprehension",
          ],
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseThumb3.png?alt=media&token=80531b58-7afe-4b39-bb6d-5dba16944073",
          title: "Spanish for Adults",
          description:
            "We offer comprehensive online Spanish classes for adults that want to learn the language while also gaining an understanding of the Hispanic culture and traditions.",
          listTitle: "Skills Acquired:",
          list: [
            "Improved reading and vocabulary",
            "Deep knowledge of Hispanic cultures",
            "Enhanced phonological awareness",
          ],
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseThumb2.png?alt=media&token=d6d4234b-fc49-4c13-8f51-5354dd28788f",
          title: "Spanish IB programs",
          description:
            "Our Spanish IB programs(PYP,MYP and Diploma) focus on the Spanish language, literature, and culture. These programs foster global awareness, and effective communication skills.",
          listTitle: "Skills Acquired:",
          list: [
            "Enhanced reading and vocabulary",
            "In-depth cultural knowledge",
            "Advanced phonological awareness",
          ],
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseThumb4.png?alt=media&token=8e6bb5c4-8d13-4281-8a9b-8c320d56bd7a",
          title: "Professional Development in Spanish",
          description:
            "Our professional development courses aim to provide language teachers with skills necessary to advance their professional development.",
          listTitle: "Skills Acquired:",
          list: [
            "Improved professional vocabulary and communication",
            "Cultural competence in Hispanic business contexts",
            "Advanced language skills",
          ],
        },
      ],
      ourSolutionsHome: {
        tag: "Our solution",
        title1: "Big or small, grow with Comligo",
        solutions: [
          {
            bold: "Customized Spanish Courses:",
            text: "Tailored to fit the unique needs of your institution.",
          },
          {
            bold: "Engaging Instruction:",
            text: "Learn from native Spanish-speaking teachers from various Hispanic countries.",
          },
          {
            bold: "Flexible and scalable:",
            text: "Our schedules and programs adapt to any class size or need.",
          },
          {
            bold: "Ongoing Support:",
            text: "Continuous assistance and resources to ensure successful language learning and integration.",
          },
        ],
        slogan1: "CONNECT",
        slogan2: "LEARN",
        slogan3: "GROW",
      },
      ourSolutionsK12: {
        tag: "Our solution",
        title1: "Courses offered for K-12 schools",
        solutions: [
          {
            bold: "",
            text: "Curriculum (including Dual Language programs) and enrichment programs for K-12 students that are expertly designed to boost student engagement and academic achievement.",
          },
          {
            bold: "",
            text: "Spanish classes for school staff and English speaking parents tailored to break language barriers and foster a more inclusive school environment.",
          },
          {
            bold: "",
            text: "PD for Spanish teachers offering advanced teaching strategies and resources that align with state education standards.",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      ourSolutionsIB: {
        tag: "Our solution",
        title1: "Courses for IB Schools",
        solutions: [
          {
            bold: "",
            text: "Expertly designed language courses that align with the IB curriculum (PYP, MYP and DP) that promote international mindset and global competence.",
          },
          {
            bold: "",
            text: "Professional development programs for language teachers offer advanced teaching strategies that align with IB standards, enhancing both educator effectiveness and student outcomes.",
          },
          {
            bold: "",
            text: "Support and professional development for the school community tailored for school staff and English-speaking parents to break down communication barriers and foster an inclusive school environment.",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      ourSolutionsHigherEd: {
        tag: "Our solution",
        title1: "Courses for Higher Education",
        solutions: [
          {
            bold: "",
            text: "Comprehensive Spanish curriculum and specialized programs which are crafted to enhance language proficiency and critical thinking preparing students for academic success.",
          },
          {
            bold: "",
            text: "Specialized programs and cultural enrichment tailored to specific academic and professional needs such as Spanish for Specific Purposes and Diversity and Culture.",
          },
          {
            bold: "",
            text: "Professional development for faculty and staff designed for university-level Spanish instructors and staff, focusing on advanced teaching strategies and up-to-date resources.",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      ourSolutionsPublic: {
        tag: "Our solution",
        title1: "Courses Offered for the public sector",
        solutions: [
          {
            bold: "",
            text: "Comligo provides comprehensive language programs, including Spanish for Diversity, Cultural Workshops and Spanish for Specific Purposes with flexible schedules and interactive learning experiences.",
          },
          {
            bold: "",
            text: "We also offer Spanish conversation clubs that promote real-life language practice and strengthen community ties.",
          },
          {
            bold: "",
            text: "Our summer camps provide a unique, game-based learning experience for different age groups, with flexible durations ranging from 2 to 8 weeks",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      ourSolutionsPrivate: {
        tag: "Our solution",
        title1: "Courses Offered for the private sector & NGOs",
        solutions: [
          {
            bold: "",
            text: "Comligo provides comprehensive language programs, including Spanish for Diversity, Cultural Workshops, and Spanish for Specific Purposes with flexible schedules and interactive learning experiences.",
          },
          {
            bold: "",
            text: "We also offer Spanish conversation clubs that promote real-life language practice and strengthen community and organizational ties.",
          },
          {
            bold: "",
            text: "Our summer camps provide a unique, game-based learning experience for different age groups, with flexible durations ranging from 2 to 8 weeks.",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      ourSolutionsCorporate: {
        tag: "Our solution",
        title1: "Courses offered for Corporate Organizations",
        solutions: [
          {
            bold: "",
            text: "Introductory and intermediate Spanish courses designed for employees at various proficiency levels, these courses build a strong foundation in Spanish.",
          },
          {
            bold: "",
            text: "Spanish for diversity and inclusion focused on promoting cultural awareness and inclusivity within corporate environments.",
          },
          {
            bold: "",
            text: "Spanish for specific professions tailored for healthcare professionals and business contexts, these courses provide relevant language skills for specialized fields.",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      yourChallengesK12: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Teachers Shortage",
          descTop:
            "Difficulty finding qualified Spanish teachers for all courses and groups, causing gaps in the provision of classes to students.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Availability of Teachers",
          descBottom:
            "Access a network of highly qualified Spanish instructors to ensure full coverage and quality teaching.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Communication Barriers",
          descTop:
            "Staff struggles with effective daily communication in Spanish, impacting interactions with Spanish-speaking students and families.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Ease of Communication",
          descBottom:
            "Comprehensive courses to enhance staff’s Spanish communication skills for better daily interactions with their Hispanic counterparts.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Lack of Practice",
          descTop:
            "Students lack sufficient real-life practice on their Spanish, limiting their Spanish language proficiency and application.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Interactive Learning",
          descBottom:
            "Engaging lessons with practical exercises to boost students' real-life Spanish skills and confidence.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Parental challenges",
          descTop:
            "English-speaking parents find it difficult to assist with the Spanish homework for their kids, affecting student support.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Family Support",
          descBottom:
            "Workshops and resources designed to help parents effectively support their children’s Spanish learning at home.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Outdated skills",
          descTop:
            "The lack of ongoing professional development for Spanish teachers, impacting their teaching effectiveness and up-to-date skills.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Advanced Training",
          descBottom:
            "Professional development resources that continuously enhance educators' skills and teaching practices.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Culture Gaps",
          descTop:
            "Students have limited exposure to Hispanic cultures, hindering their understanding and appreciation of cultural contexts.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Bridge the Gaps",
          descBottom:
            "Courses that are integrated with cultural lessons to deepen students' understanding of Hispanic cultures and contexts.",
        },
      ],
      yourChallengesIB: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Teachers Shortage",
          descTop:
            "Difficulty finding qualified Spanish teachers for all courses and groups, causing gaps in the provision of classes to students.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Availability of Teachers",
          descBottom:
            "Access a network of highly qualified Spanish instructors to ensure full coverage and quality teaching.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Communication Barriers",
          descTop:
            "Staff struggles with effective daily communication in Spanish, impacting interactions with Spanish-speaking students and families.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Ease of Communication",
          descBottom:
            "Comprehensive courses to enhance staff’s Spanish communication skills for better daily interactions with their Hispanic counterparts.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Lack of Practice",
          descTop:
            "Students lack sufficient real-life practice on their Spanish, limiting their Spanish language proficiency and application.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Interactive Learning",
          descBottom:
            "Engaging lessons with practical exercises to boost students' real-life Spanish skills and confidence.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Parental challenges",
          descTop:
            "English-speaking parents find it difficult to assist with the Spanish homework for their kids, affecting student support.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Family Support",
          descBottom:
            "Workshops and resources designed to help parents effectively support their children’s Spanish learning at home.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Outdated skills",
          descTop:
            "The lack of ongoing professional development for Spanish teachers, impacting their teaching effectiveness and up-to-date skills.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Advanced Training",
          descBottom:
            "Professional development resources that continuously enhance educators' skills and teaching practices.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Culture Gaps",
          descTop:
            "Students have limited exposure to Hispanic cultures, hindering their understanding and appreciation of cultural contexts.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Bridge the Gaps",
          descBottom:
            "Courses that are integrated with cultural lessons to deepen students' understanding of Hispanic cultures and contexts.",
        },
      ],
      yourChallengesHigherEducation: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecificialized Programs",
          descTop:
            "Difficulty finding Spanish language programs that precisely meet your institution’s specific needs and objectives.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Customized Solutions",
          descBottom:
            "Developed tailored Spanish language programs that perfectly align with your institution’s unique requirements and goals.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Untailored Classes",
          descTop:
            "Struggling to provide effective Spanish training solutions tailored for the staff of our corporate clients.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Specialized Training",
          descBottom:
            "Offered  and  targeted Spanish classes designed for corporate environments to enhance communication skills and business interactions.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Decline in Enrollment",
          descTop:
            "Facing difficulties in increasing student enrollment and participation in our Spanish language courses and programs.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Engaging Courses",
          descBottom:
            "Focused on making Spanish courses more attractive and engaging to boost student enrollment and participation.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Growth Limitation",
          descTop:
            "Limited opportunities available for staff to advance their professional development and career growth in Spanish education.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Development Programs",
          descBottom:
            "Provided advanced training and professional development workshops to enhance staff skills and support career advancement.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Teacher Shortage",
          descTop:
            "Difficulty in recruiting and retaining qualified Spanish instructors, impacting the quality and consistency of courses.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Recruitment Network",
          descBottom:
            "Access to an extensive network to find and retain highly qualified Spanish instructors, ensuring quality course delivery.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Community Challenges",
          descTop:
            "Managing and supporting grant-backed community Spanish initiatives effectively is proving to be a significant hurdle.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Program Support",
          descBottom:
            "Offer tailored support and resources to enhance the effectiveness and impact of community-focused Spanish programs.",
        },
      ],
      yourChallengesPublic: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Limited Enrichment",
          descTop:
            "Difficulty in offering diverse enrichment programs for young students, impacting engagement and learning outcomes.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Engaging Programs",
          descBottom:
            "Provided comprehensive enrichment programs tailored to boost student engagement and academic achievement.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecialized Programs",
          descTop:
            "Struggling to find specialized Spanish courses that align with your organization’s goals and community needs.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Tailored Courses",
          descBottom:
            "Spanish for Diversity and Specific Purposes courses that are customized and offered to meet the unique needs of your community.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Cultural Gaps",
          descTop:
            "Limited opportunities to promote multicultural understanding within the community.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Cultural Workshops",
          descBottom:
            "Facilitated cultural workshops that enhance community awareness and foster inclusivity.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Outdated Skills",
          descTop:
            "Community and staff have limited opportunities to enhance and practice their Spanish language skills effectively.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Interactive Clubs",
          descBottom:
            "Established Spanish conversation clubs that promote real-life language practice and skill development.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Seasonal Engagement",
          descTop:
            "Difficulty maintaining community engagement during summer periods, leading to reduced participation.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Summer Camps",
          descBottom:
            "Flexible summer camps offered with interactive learning activities, designed to keep patrons engaged and learning.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecialized Programs",
          descTop:
            "Struggle to find language programs tailored to specific organizational needs and objectives.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Tailored Courses",
          descBottom:
            "Customized Spanish courses designed to meet your unique professional and organizational goals.",
        },
      ],
      yourChallengesPrivate: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Limited Enrichment",
          descTop:
            "Difficulty in offering diverse enrichment programs for young students, impacting engagement and learning outcomes.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Engaging Programs",
          descBottom:
            "Provided comprehensive enrichment programs tailored to boost student engagement and academic achievement.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecialized Programs",
          descTop:
            "Struggling to find specialized Spanish courses that align with your organization’s goals and community needs.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Tailored Courses",
          descBottom:
            "Spanish for Diversity and Specific Purposes courses that are customized and offered to meet the unique needs of your community.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Cultural Gaps",
          descTop:
            "Limited opportunities to promote multicultural understanding within the community.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Cultural Workshops",
          descBottom:
            "Facilitated cultural workshops that enhance community awareness and foster inclusivity.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Outdated Skills",
          descTop:
            "Community and staff have limited opportunities to enhance and practice their Spanish language skills effectively.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Interactive Clubs",
          descBottom:
            "Established Spanish conversation clubs that promote real-life language practice and skill development.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Seasonal Engagement",
          descTop:
            "Difficulty maintaining community engagement during summer periods, leading to reduced participation.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Summer Camps",
          descBottom:
            "Flexible summer camps offered with interactive learning activities, designed to keep patrons engaged and learning.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecialized Programs",
          descTop:
            "Struggle to find language programs tailored to specific organizational needs and objectives.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Tailored Courses",
          descBottom:
            "Customized Spanish courses designed to meet your unique professional and organizational goals.",
        },
      ],
      yourChallengesCorporate: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecialized Programs",
          descTop:
            "Struggle to find language programs tailored to specific organizational needs and objectives.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Tailored Courses",
          descBottom:
            "Customized Spanish courses designed to meet your unique professional and organizational goals.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Outdated skills",
          descTop:
            "Staff have limited opportunities to enhance and practice their Spanish language skills effectively.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Professional Development",
          descBottom:
            "Provide targeted courses to foster continuous learning and professional growth.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Cultural Awareness",
          descTop:
            "Difficulty promoting multicultural understanding and inclusivity within the workplace.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Inclusive Training",
          descBottom:
            "Courses designed to enhance cultural awareness and foster a more inclusive work environment.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Engagement Issues",
          descTop:
            "Staff feels disengaged during traditional language learning courses.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Interactive Learning",
          descBottom:
            "Engaging and flexible courses designed to keep your staff motivated and actively involved.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Communication Gaps",
          descTop:
            "Challenges in connecting effectively with Spanish-speaking counterparts.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Enhanced Communication",
          descBottom:
            "Language training that boosts confidence in interacting with Spanish-speaking colleagues and clients.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Rigid Learning",
          descTop:
            "Traditional options lack flexibility and opportunities for self-study.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Flexible Options",
          descBottom:
            "Courses that offer flexible schedules and self-study opportunities, tailored to busy professionals.",
        },
      ],
      includedInOurCourses: [
        {
          title: "Interactive Lessons",
          description: "Enjoy engaging lessons that make",
          description1: "learning Spanish fun and effective ",
          description2: "with practical exercises.",
        },
        {
          title: "Materials for self study",
          description: "Our courses provide a wide range of",
          description1: "materials designed for self-study to",
          description2: "enhance your learning experience.",
        },
        {
          title: "Native-Speaking Instructors",
          description: "Learn from experienced,",
          description1: "native Spanish speakers who",
          description2: "provide personalized instruction.",
        },
        {
          title: "Cultural Insights",
          description: "Discover Spanish-speaking cultures",
          description1: "through music, movies, and traditions",
          description2: "to enhance your learning.",
        },
        {
          title: "Flexible Learning Options",
          description: "Choose from online classes,",
          description1: "self-paced modules, and live sessions",
          description2: "that fit your schedule.",
        },
        {
          title: "Supportive Community",
          description: "Join a vibrant group of",
          description1: "learners with access to study",
          description2: "sessions, forums, and events.",
        },
        {
          title: "Personalized Feedback",
          description: "Get tailored feedback and support",
          description1: "to help you achieve",
          description2: "your learning goals.",
        },
        {
          title: "Real-Life Practice",
          description: "Work on projects and exercises that",
          description1: "simulate real-life situations to",
          description2: "practice your Spanish skills.",
        },
      ],
      trustedCompanies: [
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/portland.png?alt=media&token=5f0bb0f8-9bb0-408a-b7c2-b7b3a021fc15",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/latino.png?alt=media&token=87d8e5a2-9559-4e40-b9b3-8d272df91989",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/longbeach.png?alt=media&token=a5cf6049-22e1-4a79-ae29-25e4f2d8109e",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/loreal.png?alt=media&token=238d6c5d-0b3b-4aaf-86d7-e3303575035f",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/canal.png?alt=media&token=4732b976-315b-422e-bae4-5c0a3eae41b2",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/deloitte.png?alt=media&token=0c7c41c5-c64b-47cc-9c0e-ea77c478a98e",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/prospect.png?alt=media&token=2ea4a829-7806-48e0-bb5a-cacbbd0c8f8e",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/arroyo.png?alt=media&token=f46e4846-6f01-47df-be74-04d33bc3f697",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/LCH.png?alt=media&token=d7c0590e-b846-4698-aaee-e0e3f459a297",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/HSBC.png?alt=media&token=eb1b3507-4e5a-4b62-8f7a-220effc2975b",
      ],
      homepageCourses: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article1.png?alt=media&token=93a07866-f775-446d-8846-7b9f0503241e",
          subText: "For Adults",
          title: "Beginner Spanish",
          p1: "Start with the basics and make learning Spanish fun and easy.",
          list: [
            {
              bold: "Foundation Building:",
              normal:
                "Begin with the basics to build a strong base for new learners.",
            },
            {
              bold: "Easy and Fun:",
              normal: "Enjoy a simple and engaging learning experience.",
            },
            {
              bold: "Practical Skills:",
              normal:
                "Learn useful phrases and vocabulary for everyday conversations.",
            },
            {
              bold: "Interactive Practice:",
              normal:
                "Participate in interactive exercises to gain confidence.",
            },
          ],
          tag: "Beginner Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article2.png?alt=media&token=17d98973-d56e-4121-9aec-d038d8b12531",
          subText: "For Adults",
          title: "Intermediate Spanish",
          p1: "Perfect for those with basic Spanish skills who want to improve their fluency.",
          list: [
            {
              bold: "Build on Basics:",
              normal: "Advance your Spanish with more grammar and vocabulary.",
            },
            {
              bold: "Expand Proficiency:",
              normal: "Gain confidence in handling more complex conversations.",
            },
            {
              bold: "Real-World Use:",
              normal: "Apply your skills in business and social settings.",
            },
            {
              bold: "Focused Support:",
              normal:
                "Get help to overcome language challenges and improve your skills.",
            },
          ],
          tag: "Intermediate Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article3.png?alt=media&token=5e6dbbf1-c3f5-4e16-82a7-f01ebf7f461f",
          subText: "For Adults",
          title: "Advanced Spanish",
          p1: "Great for improving your skills and handling complex conversations.",
          list: [
            {
              bold: "Skill Refinement:",
              normal: "Sharpen your advanced Spanish skills.",
            },
            {
              bold: "Complex Conversations:",
              normal: "Talk confidently about detailed topics.",
            },
            {
              bold: "Cultural Insights:",
              normal: "Learn more about Spanish culture and nuances.",
            },
            {
              bold: "Professional Use:",
              normal: "Communicate better in work and business settings.",
            },
          ],
          tag: "Advanced Spanish",
          button: "Read more",
        },
      ],
      comprehensiveCourses: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article1.png?alt=media&token=93a07866-f775-446d-8846-7b9f0503241e",
          subText: "For Adults",
          title: "Beginner Spanish",
          p1: "Start with the basics and make learning Spanish fun and easy.",
          list: [
            {
              bold: "Foundation Building:",
              normal:
                "Begin with the basics to build a strong base for new learners.",
            },
            {
              bold: "Easy and Fun:",
              normal: "Enjoy a simple and engaging learning experience.",
            },
            {
              bold: "Practical Skills:",
              normal:
                "Learn useful phrases and vocabulary for everyday conversations.",
            },
            {
              bold: "Interactive Practice:",
              normal:
                "Participate in interactive exercises to gain confidence.",
            },
          ],
          tag: "Beginner Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article2.png?alt=media&token=17d98973-d56e-4121-9aec-d038d8b12531",
          subText: "For Adults",
          title: "Intermediate Spanish",
          p1: "Perfect for those with basic Spanish skills who want to improve their fluency.",
          list: [
            {
              bold: "Build on Basics:",
              normal: "Advance your Spanish with more grammar and vocabulary.",
            },
            {
              bold: "Expand Proficiency:",
              normal: "Gain confidence in handling more complex conversations.",
            },
            {
              bold: "Real-World Use:",
              normal: "Apply your skills in business and social settings.",
            },
            {
              bold: "Focused Support:",
              normal:
                "Get help to overcome language challenges and improve your skills.",
            },
          ],
          tag: "Intermediate Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article3.png?alt=media&token=5e6dbbf1-c3f5-4e16-82a7-f01ebf7f461f",
          subText: "For Adults",
          title: "Advanced Spanish",
          p1: "Great for improving your skills and handling complex conversations.",
          list: [
            {
              bold: "Skill Refinement:",
              normal: "Sharpen your advanced Spanish skills.",
            },
            {
              bold: "Complex Conversations:",
              normal: "Talk confidently about detailed topics.",
            },
            {
              bold: "Cultural Insights:",
              normal: "Learn more about Spanish culture and nuances.",
            },
            {
              bold: "Professional Use:",
              normal: "Communicate better in work and business settings.",
            },
          ],
          tag: "Advanced Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article1.png?alt=media&token=93a07866-f775-446d-8846-7b9f0503241e",
          subText: "For Adults",
          title: "Beginner Spanish",
          p1: "Start with the basics and make learning Spanish fun and easy.",
          list: [
            {
              bold: "Foundation Building:",
              normal:
                "Begin with the basics to build a strong base for new learners.",
            },
            {
              bold: "Easy and Fun:",
              normal: "Enjoy a simple and engaging learning experience.",
            },
            {
              bold: "Practical Skills:",
              normal:
                "Learn useful phrases and vocabulary for everyday conversations.",
            },
            {
              bold: "Interactive Practice:",
              normal:
                "Participate in interactive exercises to gain confidence.",
            },
          ],
          tag: "Beginner Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article2.png?alt=media&token=17d98973-d56e-4121-9aec-d038d8b12531",
          subText: "For Adults",
          title: "Intermediate Spanish",
          p1: "Perfect for those with basic Spanish skills who want to improve their fluency.",
          list: [
            {
              bold: "Build on Basics:",
              normal: "Advance your Spanish with more grammar and vocabulary.",
            },
            {
              bold: "Expand Proficiency:",
              normal: "Gain confidence in handling more complex conversations.",
            },
            {
              bold: "Real-World Use:",
              normal: "Apply your skills in business and social settings.",
            },
            {
              bold: "Focused Support:",
              normal:
                "Get help to overcome language challenges and improve your skills.",
            },
          ],
          tag: "Intermediate Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article3.png?alt=media&token=5e6dbbf1-c3f5-4e16-82a7-f01ebf7f461f",
          subText: "For Adults",
          title: "Advanced Spanish",
          p1: "Great for improving your skills and handling complex conversations.",
          list: [
            {
              bold: "Skill Refinement:",
              normal: "Sharpen your advanced Spanish skills.",
            },
            {
              bold: "Complex Conversations:",
              normal: "Talk confidently about detailed topics.",
            },
            {
              bold: "Cultural Insights:",
              normal: "Learn more about Spanish culture and nuances.",
            },
            {
              bold: "Professional Use:",
              normal: "Communicate better in work and business settings.",
            },
          ],
          tag: "Advanced Spanish",
          button: "Read more",
        },
      ],
      testimonials: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials3.png?alt=media&token=e410fac4-5dd8-4bf9-966d-72c5e1583387",
          text: "Throughout the courses provided by Comligo, our students have not only improved their Spanish language proficiency but have also become more culturally aware and competent.",
          name: "Principal, High school, NY",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials1.png?alt=media&token=8e76bf8f-07ed-4b18-b4ac-3a2288c4da4e",
          text: "Comligo educators create a supportive and inclusive learning environment that encourages participants to push their boundaries and excel in their language acquisition journey.",
          name: "Superintendent, School district, WA",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials2.png?alt=media&token=983dc065-9cfd-4326-adfa-7e539ec53b44",
          text: "We are looking forward to partnering again with Comligo again this upcoming school year and would recommend Comligo to anyone looking to bring this learning opportunity to their classrooms.",
          name: "Head of School, Charter School, TX",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials3.png?alt=media&token=e410fac4-5dd8-4bf9-966d-72c5e1583387",
          text: "We have been very pleased with the classes and the professionalism of the Comligo instructors.The staff was very pleased with the classes, the instructors and the overall quality.",
          name: "Chief Operations Officer, Non-profit, TN",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials1.png?alt=media&token=8e76bf8f-07ed-4b18-b4ac-3a2288c4da4e",
          text: "Comligo has been an invaluable partner to our organization. Their communication was personalized, ensuring that every aspect of their service was tailored to meet our specific requirements.",
          name: "HR Manager, Non-profit, CA",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials2.png?alt=media&token=983dc065-9cfd-4326-adfa-7e539ec53b44",
          text: "Comligo Spanish has been very helpful to me with learning how to speak Spanish via their interactive, engaging Spanish classes. I look forward to continuing my partnership with Comligo Spanish.",
          name: "CEO, Healthcare Group, PA",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials3.png?alt=media&token=e410fac4-5dd8-4bf9-966d-72c5e1583387",
          text: "Milena is a wonderful teacher! She encourages us and helps us learn. I am grateful to her for helping me grow my Spanish skills so much in the last year and a half.",
          name: "Staff, School District, WA",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials1.png?alt=media&token=8e76bf8f-07ed-4b18-b4ac-3a2288c4da4e",
          text: "I am extremely pleased with my progress in the Spanish language. Gabriela has been instrumental in boosting my confidence to use it daily at work.",
          name: "Staff, School District, OR",
        },
      ],
      empoweringLanguage: [
        {
          icon: "mdi mdi-heart-outline",
          title: "Boosting",
          title1: " Student Interest",
          description: "Building a lively environment",
          description1: "that sparks students'",
          description2: "desire to learn Spanish.",
        },
        {
          icon: "mdi mdi-web",
          title: "Cultural",
          title1: "Understanding",
          description: "Engaging students with",
          description1: "real experiences alongside",
          description2: "native Spanish teachers.",
        },
        {
          icon: "mdi mdi-chart-line",
          title: "Flexible",
          title1: "Programs",
          description: "Offering adaptable",
          description1: "language programs for",
          description2: "any institution's size.",
        },
        {
          icon: "mdi mdi-cog-outline",
          title: "Teacher",
          title1: "Support",
          description: "Equipping teachers and staff",
          description1: "with vital tools for effective",
          description2: "Spanish communication.",
        },
      ],
      homeCaseStudies: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/successStoriesImg.png?alt=media&token=6f961117-63f9-4325-a20b-0c4bdab28dc7",
          subText: "Case Studies",
          title:
            "How Comligo Enhanced Language Learning for XYZ School District",
          description:
            "Explore how Comligo’s tailored Spanish programs transformed language education at XYZ School District. Learn how our interactive, game-based approach improved student engagement and proficiency.",
          tag: "For K-12 schools",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/successStoriesImg.png?alt=media&token=6f961117-63f9-4325-a20b-0c4bdab28dc7",
          subText: "Case Studies",
          title:
            "How Comligo Enhanced Language Learning for XYZ School District",
          description:
            "Explore how Comligo’s tailored Spanish programs transformed language education at XYZ School District. Learn how our interactive, game-based approach improved student engagement and proficiency.",
          tag: "For K-12 schools",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/successStoriesImg.png?alt=media&token=6f961117-63f9-4325-a20b-0c4bdab28dc7",
          subText: "Case Studies",
          title:
            "How Comligo Enhanced Language Learning for XYZ School District",
          description:
            "Explore how Comligo’s tailored Spanish programs transformed language education at XYZ School District. Learn how our interactive, game-based approach improved student engagement and proficiency.",
          tag: "For K-12 schools",
          button: "Read more",
        },
      ],
      homepageInsights: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/insights1.png?alt=media&token=f101783e-dcf9-4ddd-bf97-fe41727ad565",
          subText: "Online learning",
          title: "How AI is Revolutionizing Spanish Language Education",
          p1: "Explore the transformative power of AI in language learning. Learn how Comligo’s AI-driven tools personalize your Spanish learning experience and help you achieve fluency faster.",
          author: "Author Name",
          date: "12 July 2023",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/insights2.png?alt=media&token=ca25dc02-e564-4e3c-8e3f-b52410b1862b",
          subText: "Spanish language and culture",
          title:
            "Comligo’s 2024 Impact Report: Advancing Global Language Education",
          p1: "Delve into our latest impact report and see the strides Comligo has made in advancing language education worldwide. Get insights into our successes and future goals.",
          author: "Author Name",
          date: "12 July 2023",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/insights3.png?alt=media&token=3c840a03-ac63-4906-940e-a63be3490133",
          subText: "Spanish language and culture",
          title: "Interactive Strategies to Boost Your Spanish Learning",
          p1: "Unlock the secrets to mastering Spanish with engaging, game-based learning methods. Discover how interactive activities can enhance your language skills and make learning fun.",
          author: "Author Name",
          date: "12 July 2023",
          button: "Read more",
        },
      ],
      collaborationsTimelineK12: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "The Comligo academic coordinator sets up the classes and shares the necessary details with the organization.",
        },
        {
          color: "#E05025",
          title: "Teacher & Resources",
          desc: "Assignment of native Spanish teachers and provision of access to a dedicated site with all course materials activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Start classes with the assigned instructor and give access to a forum for ongoing communication between students and teacher.",
        },
      ],
      collaborationsTimelineIB: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "The Comligo academic coordinator works closely with your school to establish a tailored program ensuring seamless integration with your existing curriculum.",
        },
        {
          color: "#E05025",
          title: "Teacher & Resources",
          desc: "Assignment of experienced native language instructors and access to a dedicated site with comprehensive course materials and activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Begin classes with the assigned instructor and enjoy ongoing support through a dedicated communication forum for students and teachers.",
        },
      ],
      collaborationsTimelineHigherEducation: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "Collaborate with Comligo’s academic coordinators to design a tailored program that integrates seamlessly with your existing curriculum.",
        },
        {
          color: "#E05025",
          title: "Faculty & Resources",
          desc: "Assign experienced, native language instructors and provide access to a comprehensive suite of course materials and activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Initiate classes with assigned instructors and benefit from ongoing support through dedicated communication channels for students and faculty.",
        },
      ],
      collaborationsTimelinePublic: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "The Comligo academic coordinator establishes the classes and shares relevant details with your organization.",
        },
        {
          color: "#E05025",
          title: "Teacher & Resources",
          desc: "Assign native Spanish teachers and provide access to a dedicated site with all course materials and activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Start classes with the assigned instructor, and give access to a forum for ongoing communication between patrons and the teacher.",
        },
      ],
      collaborationsTimelinePrivate: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "The Comligo academic coordinator establishes the classes and shares relevant details with your organization.",
        },
        {
          color: "#E05025",
          title: "Teacher & Resources",
          desc: "Assign native Spanish teachers and provide access to a dedicated site with all course materials and activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Start classes with the assigned instructor, and give access to a forum for ongoing communication between patrons and the teacher.",
        },
      ],
      collaborationsTimelineCorporate: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "The Comligo academic coordinator establishes the classes and shares relevant details with your organization.",
        },
        {
          color: "#E05025",
          title: "Teacher & Resources",
          desc: "Assign native Spanish teachers and provide access to a dedicated site with all course materials and activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Start classes with the assigned instructor, and give access to a forum for ongoing communication between patrons and the teacher.",
        },
      ],
      pricingPlans: {
        tag: "Pricing Plans",
        title: "Choose the Perfect Plan for Your Needs",
        desc: "Whether you're looking for personalized instruction or collaborative learning, we have a plan that fits your requirements.",
        plans: [
          {
            title: "Individual Classes",
            subTitle: "For individuals and organizations",
            paragraph:
              "Get personalized attention and a customized learning experience with our individual classes.",
            includedTitle: "What's Included:",
            included: [
              "One-on-one sessions with a qualified Spanish instructor",
              "Flexible scheduling to accommodate your availability",
              "Customized curriculum aligned with your learning goals",
              "Access to our online learning materials and resources",
              "Regular feedback and progress reports",
            ],
          },
          {
            title: "Group Classes",
            subTitle: "For organizations",
            paragraph:
              "Enjoy a collaborative and interactive learning environment with our group classes.",
            includedTitle: "What's Included:",
            included: [
              "Small group sessions led by a qualified Spanish instructor",
              "Scheduled classes at convenient times",
              "Engaging curriculum designed for group learning",
              "Access to our online learning materials and resources",
              "Opportunities for peer interaction and support",
            ],
          },
        ],
      },
      frequentlyAskedQuestions: {
        title: "Frequently Asked Questions",
        list: [
          {
            question: "What are the IT requirements for Comligo classes?",
            answer:
              "Very simple! A computer, internet connection and a browser. A headset with a microphone is recommended.",
          },
          {
            question: "Are Comligo classes 100% live?",
            answer:
              "Yes, all classes are taught live by a native teacher, with real-time interaction.",
          },
          {
            question:
              "Does Comligo offer other courses besides regular Spanish classes?",
            answer:
              "Yes! In addition to our regular Spanish language courses for K-12 students and adults, we offer a “Spanish for Diversity” course and various cultural courses.",
          },
          {
            question: "How does Comligo handle exams and grading?",
            answer:
              "We provide real-time feedback, tests and evaluations based on school standards, approved by departments of education.",
          },
          {
            question: "What qualifications do Comligo teachers have?",
            answer:
              "All teachers are native Spanish speakers with a master’s degree in teaching or Spanish.",
          },
          {
            question: "What curriculum does Comligo follow?",
            answer:
              "We follow ACTFL standards, incorporating authentic cultural materials, which are widely accepted across the United States, tailored to meet additional state-specific requirements.",
          },
          {
            question: "How are assignments managed?",
            answer:
              "Assignments are sent through Padlet, where students access and submit their work.",
          },
          {
            question: "What does a typical Comligo class look like?",
            answer:
              "Students join via a link, and our teachers lead interactive, live sessions using tools like Nearpod.",
          },
          {
            question: "How are student levels and groups arranged?",
            answer:
              "We perform level tests to ensure students are placed in the appropriate Spanish level and group students by age and proficiency.",
          },
          {
            question: "Can Comligo adapt its schedule to a school’s calendar?",
            answer:
              "Yes, we are flexible and can adjust our schedule to fit your school’s needs. One week’s notice is required for such changes.",
          },
          {
            question: "What is the maximum class size?",
            answer: "Our standard class size is up to 15 students.",
          },
          {
            question: "Can parents or schools meet with Comligo teachers?",
            answer:
              "Yes, meetings with teachers can be arranged to discuss student performance. Scheduling in advance is appreciated due to our teachers' busy schedules.",
          },
        ],
      },
      notification: {
        show: false,
        title: "",
        message: "",
        type: "",
      },
      progress: 0,
      dialog: false,
      courses: [],
      course: {},
      breadcrumbs: [],
      articles: [],
      article: {},
      eBooks: [],
      eBook: {},
      guides: [],
      guide: {},
      events: [],
      event: {},
      caseStudies: [],
      caseStudy: {},
      k12Providers: [],
      k12Provider: {},
      cookiePolicy: {},
      consent: {},
    };
  },
  getters,
  mutations,
  actions: {},
  modules: {},
});
