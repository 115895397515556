<template>
  <div class="notification-wrapper" :class="hideNotification()">
    <v-alert
      :text="getNotification.message"
      :title="getNotification.title"
      :type="getNotification.type"
    ></v-alert>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "notificationComponent",
  computed: {
    ...mapGetters(["getNotification"]),
    ...mapState(["notification"]),
  },
  methods: {
    ...mapMutations(["setNotification"]),
    hideNotification() {
      setTimeout(() => {
        this.setNotification({
          show: false,
          title: "",
          message: "",
          type: "",
        });
      }, 5000);
    },
  },
};
</script>

<style></style>
