import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/spanish-courses-overview",
    name: "courses",
    component: () => import("@/views/Courses.vue"),
  },
  {
    path: "/course/:id",
    name: "course",
    component: () => import("@/views/Course.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404Component.vue"),
  },
  {
    path: "/spanish-learning-articles",
    name: "articles",
    component: () => import("@/views/Articles.vue"),
  },
  {
    path: "/article/:id",
    name: "article",
    component: () => import("@/views/Article.vue"),
  },
  {
    path: "/spanish-learning-ebooks",
    name: "ebooks",
    component: () => import("@/views/EBooks.vue"),
  },
  {
    path: "/eBook/:id",
    name: "ebook",
    component: () => import("@/views/eBook.vue"),
  },
  {
    path: "/spanish-case-studies",
    name: "case studies",
    component: () => import("@/views/CaseStudies.vue"),
  },
  {
    path: "/case-study/:id",
    name: "case study",
    component: () => import("@/views/CaseStudy.vue"),
  },
  {
    path: "/k-12-schools",
    name: "k12",
    component: () => import("@/views/K12Component.vue"),
  },
  {
    path: "/ib-schools",
    name: "IB schools",
    component: () => import("@/views/IBschools.vue"),
  },
  {
    path: "/K12-program/:id",
    name: "k12 provider",
    component: () => import("@/views/K12Provider.vue"),
  },
  {
    path: "/doe-k12-approved-programs",
    name: "k12 providers",
    component: () => import("@/views/K12Providers.vue"),
  },
  {
    path: "/policies/:id",
    name: "policies",
    component: () => import("@/views/cookiesPolicy.vue"),
  },
  {
    path: "/:id",
    name: "policy",
    component: () => import("@/views/cookiesPolicy.vue"),
  },
  {
    path: "/public-sector",
    name: "public and non profits",
    component: () => import("@/views/nonProfits.vue"),
  },
  {
    path: "/private-sector-ngos",
    name: "private sector",
    component: () => import("@/views/privateSector.vue"),
  },
  {
    path: "/corporate-organizations",
    name: "corporates",
    component: () => import("@/views/corporates.vue"),
  },
  {
    path: "/higher-education",
    name: "higher education",
    component: () => import("@/views/higherEducation.vue"),
  },
  {
    path: "/spanish-course-pricing",
    name: "pricing",
    component: () => import("@/views/PricingComponent.vue"),
  },
  {
    path: "/about-comligo/:tab?",
    name: "about us",
    component: () => import("@/views/AboutUs.vue"),
  },
  {
    path: "/contact-comligo",
    name: "get in touch",
    component: () => import("@/views/getInTouch.vue"),
  },
  {
    path: "/spanish-learning-resources",
    name: "resources",
    component: () => import("@/views/resourcesComponent.vue"),
  },
  {
    path: "/resource/:id",
    name: "resource",
    component: () => import("@/views/resourcePage.vue"),
  },
  {
    path: "/comligo-spanish-events",
    name: "events",
    component: () => import("@/views/events.vue"),
  },
  {
    path: "/event/:id",
    name: "event",
    component: () => import("@/views/eventPage.vue"),
  },
  {
    path: "/book-demo",
    name: "Book a free demo",
    component: () => import("@/views/bookADemo.vue"),
  },
  {
    path: "/personal-information",
    name: "Personal information",
    component: () => import("@/views/personalInformation.vue"),
  },
  {
    path: "/faqs",
    name: "faqs",
    component: () => import("@/views/frequentlyAskedQuestions.vue"),
  },
  {
    path: "/corporate-brochure-all",
    name: "corporate brochure all",
    component: () => import("@/views/brochureAll.vue"),
  },
  {
    path: "/corporate-brochure",
    name: "corporate brochure",
    component: () => import("@/views/brochureOne.vue"),
  },
  {
    path: "/corporate-brochure-two",
    name: "corporate brochure two",
    component: () => import("@/views/brochureTwo.vue"),
  },
  {
    path: "/corporate-brochure-three",
    name: "corporate brochure three",
    component: () => import("@/views/brochureThree.vue"),
  },
  {
    path: "/corporate-brochure-four",
    name: "corporate brochure four",
    component: () => import("@/views/brochureFour.vue"),
  },
  {
    path: "/corporate-brochure-2",
    name: "corporate brochure two",
    component: () => import("@/views/brochure2.vue"),
  },
  {
    path: "/corporate-brochure-3",
    name: "corporate brochure three",
    component: () => import("@/views/brochure3.vue"),
  },
  {
    path: "/corporate-brochure-4",
    name: "corporate brochure four",
    component: () => import("@/views/brochure4.vue"),
  },
  {
    path: "/hispanic-artists-workshop",
    name: "hispanic artists workshop",
    component: () => import("@/views/blogs/HispanicArticWorkshop.vue"),
  },
  {
    path: "/ib-spanish",
    name: "IB spanish",
    component: () => import("@/views/blogs/IBSpanish.vue"),
  },
  {
    path: "/importance-of-spanish",
    name: "importance of spanish",
    component: () => import("@/views/blogs/ImportanceOfSpanish.vue"),
  },
  {
    path: "/general-spanish",
    name: "general spanish",
    component: () => import("@/views/blogs/generalSpanish.vue"),
  },
  {
    path: "/spanish11",
    name: "spanish 1.1",
    component: () => import("@/views/blogs/spanishMiddleSchool.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
